import { isset } from '@aerisweather/javascript-sdk/dist/utils';
import { toName } from '@aerisweather/javascript-sdk/dist/utils/strings';
import format from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import { Badge, Button, DataTableOpts, Stack, Text } from 'pws-design-system/design-system';
import React from 'react';
import { AppContext } from '../../../context/auth';
import { getConfidenceStyle } from '../../../models/stations/Station';
import Stations from '../../../models/stations/Stations';
import DataTable from '../../ui/DataTable';
import StatusBadge from '../../ui/StatusBadge';

const formatPlace = (location: any = {}): string => ['city', 'state', 'country']
    .reduce((result, key) => {
        let v = location[key];
        if (v) {
            if (key === 'city') {
                v = toName(v);
            } else {
                v = v.toUpperCase();
            }
        }
        result.push(v);
        return result;
    }, [])
    .filter((v) => isset(v))
    .join(', ');

const PRIMARY_COLS = ['name', 'id', 'location', 'status', 'confidence', 'actions'];
const USER_COLS = ['name', 'id', 'location', 'status', 'lastUpdate', 'registered', 'actions'];
const ADMIN_COLS = ['name', 'id', 'location', 'user', 'status', 'confidence', 'lastUpdate', 'registered', 'actions'];

const getColumns = (
    isAdmin: boolean = false,
    reduce: boolean = false,
    onViewDashboard: (index: number) => void,
    onSelectRow: (index: number) => void,
): any[] => {
    const COLUMNS: { [key: string]: any } = {
        name: {
            title: 'Station',
            field: 'displayName',
            align: 'left',
            width: 200,
            formatter: (value: any): any => (
                <Text fontSize="md" fontWeight="bold" isTruncated>{value}</Text>
            ),
        },
        id: {
            title: 'ID',
            field: 'stationId',
            align: 'left',
            width: 200,
        },
        location: {
            title: 'Location',
            field: 'location',
            align: 'left',
            width: 200,
            formatter: (value: any = {}): any => formatPlace(value),
        },
        user: {
            title: 'User',
            field: 'user.name',
            align: 'left',
            width: 100,
        },
        status: {
            title: 'Status',
            field: 'status',
            width: 100,
            formatter: (value: any): any => (
                <StatusBadge value={value} />
            ),
        },
        confidence: {
            title: 'Confidence',
            field: 'confidence',
            width: 80,
            formatter: (value: any): any => {
                const { style, color, text } = getConfidenceStyle(value);
                return (
                    <Badge variant={style} variantColor={color} color={text}>{value}</Badge>
                );
            },
        },
        lastUpdate: {
            id: 'last-update',
            title: 'Last Update',
            field: 'lastUpdatedAt',
            width: 120,
            formatter: (value: any) => {
                if (isset(value)) {
                    return `${formatDistance(new Date(), new Date(value))} ago`;
                }
                return 'N/A';
            },
        },
        registered: {
            id: 'registered',
            title: 'Registered',
            field: 'registeredAt',
            width: 120,
            formatter: (value: any) => {
                if (isset(value)) {
                    return format(new Date(value), 'MMM d, yyyy');
                }
                return 'N/A';
            },
        },
        actions: {
            id: 'actions',
            width: 150,
            formatter: (value: any, cell: any): any => (
                <Stack justify="flex-end" isInline>
                    <Button
                        size="xs"
                        variant="outline"
                        variantColor="default"
                        onClick={(e: any) => {
                            e.stopPropagation();
                            onViewDashboard(cell.row.index);
                        }}
                    >
                        Dashboard
                    </Button>
                    <Button
                        size="xs"
                        variant="solid"
                        variantColor="default"
                        onClick={() => onSelectRow(cell.row.index)}
                    >
                        Edit
                    </Button>
                </Stack>
            ),
        },
    };

    let keys = isAdmin ? ADMIN_COLS : USER_COLS;
    if (reduce) {
        keys = keys.filter((key) => PRIMARY_COLS.indexOf(key) >= 0);
    }

    return keys.map((key) => COLUMNS[key]);
};

interface StationsTableViewProps extends DataTableOpts {
    stations: Stations;
    isReduced?: boolean;
    onSelectRow?: (index: number) => void;
    onViewDashboard?: (index: number) => void;
}

const StationsTableView = ({
    stations,
    isReduced = false,
    onSelectRow = () => {},
    onViewDashboard = () => {},
    ...rest
}: StationsTableViewProps) => (
    <AppContext.Consumer>
        {(session) => (
            <DataTable
                data={stations ? stations.toArray() : []}
                columns={(
                    getColumns(session.user.isAdmin, isReduced, onViewDashboard, onSelectRow)
                )}
                itemSize={46}
                onRowClick={(data: any) => onSelectRow(data.index)}
                enablePagination
                manualPagination
                manualSearch
                manualFilters
                {...rest}
            />
        )}
    </AppContext.Consumer>
);

export default StationsTableView;
