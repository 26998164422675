import React, { useState, useEffect, useLayoutEffect } from 'react';
import { navigate } from 'gatsby';
import { Absolute, Box, Button, Hide, Icon, Stack, Spinner } from 'pws-design-system/design-system';
import useDebouncedEffect from 'pws-design-system/design-system/hooks/useDebouncedEffect';
import { get } from '@aerisweather/javascript-sdk/dist/utils';
import DashboardLayout from '../layouts/DashboardLayout';
import ContentPanelLayout from '../layouts/ContentPanelLayout';
import StationsView from '../views/stations/StationsView';
import StationCardListView from '../views/stations/StationCardListView';
import { Stations, Station } from '../../models/stations';
import { ListType, StationFilters, StationListType } from '../../types';
import SegmentedControl from '../ui/SegmentedControl';
import useAuth from '../../context/auth';
import useLocalStorage from '../hooks/useLocalStorage';
import useApi, { Api } from '../hooks/api/useApi';
import ProtectedResource from '../ProtectedResource';
import Toast, { useToast } from '../ui/Toast';

interface StationListPageProps {}

const StationListPageContent = () => {
    const session = useAuth();
    const defaultListType = session.isAdmin ? ListType.Tabular : ListType.Grid;
    const [userListType, setUserListType] = session.isAdmin ? useState(ListType.Tabular) : useLocalStorage<string>('pws-admin:station-list-type', defaultListType);
    const [listType, setListType] = useState<string>(userListType);
    const [stationListType, setStationListType] = useState<string>(session.isAdmin ? StationListType.All : StationListType.User);
    const [stations, setStations] = useState<Stations>(new Stations());
    const [pageIndex, setPageIndex] = useState<number>(null);
    const [filters, setFilters] = useState<StationFilters>();
    const toast = useToast();

    const { request: getStations, result, paging, isLoading } = useApi<Stations>((api: Api, params: any) => (
        api.routes.stations.getStations(params)
    ));

    const handleAddStation = () => {
        navigate('/stations/add');
    };

    const handleSelectStation = (station: Station) => {
        navigate(`/station/${station.id}/profile`);
    };

    const handleViewStationDashboard = (station: Station) => {
        window.open(station.dashboardUrl, '_blank');
    };

    const handlePageChange = (index: number) => {
        if (index !== pageIndex) {
            setPageIndex(index);
        }
    };

    const handleFiltersChange = (value: StationFilters) => {
        setFilters(value);
    };

    useDebouncedEffect(() => {
        const { query, filters: searchFilters, type } = filters || {};
        const params: any = { limit: paging.limit, page: pageIndex + 1 };
        const queries: string[] = [];

        if (session.isAdmin && stationListType === StationListType.User) {
            queries.push(`userId:${session.user.id}`);
        }
        if (type && type !== 'all') {
            queries.push(type === 'inactive' ? 'active:false' : 'active:true');
        }
        if (query && query.length > 0) {
            queries.push(`stationId:^${query}`);
        }
        if (Array.isArray(searchFilters) && searchFilters.length > 0) {
            const combined = searchFilters.map((prop) => (
                `${prop.property}:^${prop.query}`
            ));
            queries.push(combined.join(','));
        }
        if (queries.length > 0) {
            params.query = queries.join(',');
        }
        getStations(params);
    }, 500, [filters, pageIndex, stationListType]);

    useEffect(() => {
        setPageIndex(0);
    }, [filters]);

    useEffect(() => {
        if (result.success) {
            setStations(result.object);
        } else if (result.error) {
            // TODO: display error message
            console.error('Stations failed to load', result.error.message);
        }
    }, [result]);

    useEffect(() => {
        if (stations.count > 10) {
            setUserListType(ListType.Tabular);
        }
    }, [stations]);

    useEffect(() => {
        setUserListType(listType);
    }, [listType]);

    useEffect(() => {
        if (stationListType === StationListType.All) {
            setListType(ListType.Tabular);
        }
    }, [stationListType]);

    useLayoutEffect(() => {
        const urlParams = new URLSearchParams(
            window.location.search,
        ) as URLSearchParamsExtended;

        if (urlParams.has('toast')) {
            const toasts = {
                emailVerified: {
                    variant: 'success',
                    title: 'Email Verified',
                    message:
                        'Thank you for registering an account with PWSWeather. You can begin by adding your personal weather station.',
                    icon: 'check-circle',
                },
                emailUpdated: {
                    variant: 'success',
                    title: 'Email Updated',
                    message:
                        'Your email has been updated successfully.',
                    icon: 'check-circle',
                },
                passwordUpdated: {
                    variant: 'success',
                    title: 'Password Updated',
                    message:
                        'Your password has been updated successfully.',
                    icon: 'check-circle',
                },
            };

            const toastParam = urlParams.get('toast');

            if (toastParam) {
                urlParams.delete('toast');
                toast({
                    duration: 10000,
                    isClosable: true,
                    render: () => (
                        <Toast
                            {...get(toasts, toastParam)}
                        />
                    ),
                });

                window.history.replaceState(
                    {},
                    '',
                    `${location.pathname}${
                        Array.from(urlParams.keys()).length ? `?${urlParams}` : ''
                    }`,
                );
            }
        }
    });

    return (
        <ContentPanelLayout
            title="Stations"
            rightElement={
                session.isAdmin === true && (
                    <SegmentedControl mb={3} defaultValue={stationListType} onChange={(value: ListType) => setStationListType(value)}>
                        <Button value={StationListType.All}>All Stations</Button>
                        <Button value={StationListType.User}>My Stations</Button>
                    </SegmentedControl>
                )
            }
            toolbarElement={(
                <Stack spacing={4} align="center" isInline>
                    {(session.isAdmin === false || stationListType === StationListType.User) && (
                        <Hide mobile>
                            <SegmentedControl defaultValue={listType} onChange={(value: ListType) => setListType(value)}>
                                <Button value={ListType.Grid}>Grid</Button>
                                <Button value={ListType.Tabular}>Tabular</Button>
                            </SegmentedControl>
                        </Hide>
                    )}
                    <Button variantColor="brand-blue" width="44px" height="44px" pl={0} pr={0} aria-label="Add a station" onClick={handleAddStation}>
                        <Icon name="add" size="18px" />
                    </Button>
                </Stack>
            )}
        >
            <Box>
                {session.isAdmin === false && (
                    <Hide tablet desktop>
                        <SegmentedControl mb={3} defaultValue={listType} onChange={(value: ListType) => setListType(value)}>
                            <Button value={ListType.Grid}>Cards</Button>
                            <Button value={ListType.Tabular}>Tabular</Button>
                        </SegmentedControl>
                    </Hide>
                )}
                {listType === ListType.Grid && (
                    isLoading ? (
                        <Box width="full" minHeight="200px" position="relative">
                            <Absolute anchor="center">
                                <Spinner size="xl" />
                            </Absolute>
                        </Box>
                    ) : (
                        <StationCardListView
                            stations={stations}
                            onSelectStation={handleSelectStation}
                            onViewStationDashboard={handleViewStationDashboard}
                            onAddStation={handleAddStation}
                        />
                    )
                )}
                {listType === ListType.Tabular && (
                    <StationsView
                        stations={stations}
                        pagination={result.paging}
                        isLoading={isLoading}
                        onSelectStation={handleSelectStation}
                        onViewStationDashboard={handleViewStationDashboard}
                        onPageChange={handlePageChange}
                        onFiltersChange={handleFiltersChange}
                    />
                )}
            </Box>
        </ContentPanelLayout>
    );
};

const StationListPage = (props: StationListPageProps & any) => (
    <ProtectedResource>
        <DashboardLayout title="Stations" {...props}>
            <StationListPageContent />
        </DashboardLayout>
    </ProtectedResource>
);

export default StationListPage;
