// https://github.com/rehooks/local-storage

import { useState, useEffect, useCallback } from 'react';

interface KeyValuePair<K, V> {
    key: K;
    value: V;
}

const isBrowser = (): boolean => typeof window !== 'undefined';

const tryParse = (value: string): any => {
    try {
        return JSON.parse(value);
    } catch {
        return value;
    }
};

const EventClass = isBrowser() ? CustomEvent : Object;

class LocalStorageChanged<T> extends EventClass<KeyValuePair<string, T>> {

    static eventName = 'onLocalStorageChange';

    constructor(payload: KeyValuePair<string, T>) {
        super(LocalStorageChanged.eventName, { detail: payload });
    }

}

function isTypeOfLocalStorageChanged<T>(e: any): e is LocalStorageChanged<T> {
    return (!!e) && (e instanceof LocalStorageChanged || (e.detail && e.type === LocalStorageChanged.eventName));
}

function writeStorage<T>(key: string, value: T) {
    try {
        localStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : `${value}`);
        if (isBrowser()) {
            const event = new LocalStorageChanged({ key, value });
            window.dispatchEvent(<any>event);
        }
    } catch (err) {
        if (err instanceof TypeError && err.message.includes('circular structure')) {
            //
        }
        throw err;
    }
}

const deleteStorage = (key: string) => {
    localStorage.removeItem(key);
    if (isBrowser()) {
        const event = new LocalStorageChanged({ key, value: '' });
        window.dispatchEvent(<any>event);
    }
};

function useLocalStorage<T = string>(key: string, initialValue?: T): [T | null, (newValue: T) => void, () => void] { // eslint-disable-line max-len
    if (!isBrowser()) {
        return [null, () => {}, () => {}];
    }

    const [localState, setLocalState] = useState<T>(
        localStorage.getItem(key) === null ? initialValue : tryParse(localStorage.getItem(key)),
    );

    const onLocalStorageChange = (e: any | StorageEvent) => {
        if (isTypeOfLocalStorageChanged(e)) {
            if (e.detail.key === key) {
                setLocalState(<any>e.detail.value);
            }
        } else if (e.key === key) {
            if (e.newValue) {
                setLocalState(tryParse(e.newValue));
            }
        }
    };

    const canWrite = (): boolean => localStorage.getItem(key) === null;

    // update localState to reflect key changes
    useEffect(() => {
        setLocalState(localStorage.getItem(key) === null ? initialValue : tryParse(localStorage.getItem(key)));
    }, [key]);

    useEffect(() => {
        const listener = (e: Event) => onLocalStorageChange(e as LocalStorageChanged<T>);
        window.addEventListener(LocalStorageChanged.eventName, listener);
        // the storage event only works in the context of other documents (e.g. other tabs)
        window.addEventListener('storage', listener);

        // write initial value to the local storage if it's not present or contains invalid data
        if (typeof initialValue !== 'undefined' && canWrite()) {
            // write
        }

        return () => {
            window.removeEventListener(LocalStorageChanged.eventName, listener);
            window.removeEventListener('storage', listener);
        };
    }, [key]);

    const writeState = useCallback((value: T) => writeStorage(key, value), [key]);
    const deleteState = useCallback(() => deleteStorage(key), [key]);

    return [localState === null ? initialValue : localState, writeState, deleteState];
}

export default useLocalStorage;
