export type EasingCurve = [number, number, number, number];

export enum ListType {
    Tabular = 'tabular',
    Grid = 'grid'
}

export enum StationListType {
    All = 'all',
    User = 'user'
}

export type StationFilters = {
    type?: string;
    query?: string;
    filters?: any[];
};

export type UserFilters = {
    type?: string;
    query?: string;
    filters?: any[];
};

export type BadgeStyle = 'solid' | 'subtle' | 'outline';
