import React from 'react';
import { Absolute, Box, Icon, Text, BoxProps } from 'pws-design-system/design-system';
import Card from '../../ui/Card';

interface StationCardProps extends BoxProps {
    onClick: () => void;
}

const AddStationCard = ({
    onClick = () => {},
    ...rest
}: StationCardProps) => (
    <Card
        borderStyle="dashed"
        borderWidth="3px"
        borderColor="bg.300"
        bg="transparent"
        p={3}
        overflow="hidden"
        position="relative"
        cursor="pointer"
        onClick={onClick}
        _hover={{
            borderColor: 'brand.lightBlue.base',
        }}
        {...rest}
    >
        <Absolute anchor="center">
            <Box textAlign="center">
                <Box width="44px" height="44px" borderColor="bg.500" borderWidth="2px" mx="auto" rounded="full" position="relative">
                    <Absolute anchor="center">
                        <Icon name="add" size="18px" height="20px" color="bg.500" />
                    </Absolute>
                </Box>
            </Box>
            <Text mt={3} variant="title3" fontWeight="bold" color="bg.500">Add a station</Text>
        </Absolute>
    </Card>
);

export default AddStationCard;
