import React, { useState, useRef, useEffect } from 'react';
import {
    Box,
    ExpandingMenu,
    Hide,
    Icon,
    Stack,
    Text,
    TextInput,
    BoxProps,
    TableFilterStack,
} from 'pws-design-system/design-system';
import useResizeAware from 'pws-design-system/design-system/hooks/useResizeAware';
import { Helmet } from 'react-helmet';
import StationsTableView from './StationsTableView';
import StationsListView from './StationsListView';
import { StationFilters } from '../../../types';
import { Stations, Station } from '../../../models/stations';
import PagingInfo from '../../../api/PagingInfo';

const FILTER_FIELDS = [{
    name: 'stationId',
    label: 'Station ID',
}, {
    name: 'name',
    label: 'Name',
}, {
    name: 'location.city',
    label: 'City',
}, {
    name: 'location.country',
    label: 'Country',
}];

const SelectedMenuItem = ({ label }: any) => (
    <Text
        fontSize="xs"
        fontWeight="bold"
        letterSpacing="widest"
        textTransform="uppercase"
        isTruncated
    >
        {label}
    </Text>
);

interface StationsViewProps extends BoxProps {
    stations: Stations;
    pagination?: PagingInfo;
    error?: any;
    includeToolbar?: boolean;
    pageSize?: number;
    isLoading?: boolean;
    isUserProfile?: boolean;
    onSelectStation?: (station: Station) => void;
    onViewStationDashboard?: (station: Station) => void;
    onPageChange?: (index: number, size: number) => void;
    onFiltersChange?: (filters: StationFilters) => void;
}

let localStations;
const StationsView = ({
    stations,
    pagination = {},
    error,
    includeToolbar = true,
    pageSize = 20,
    isLoading = false,
    // isReduced = false,
    isUserProfile = false,
    onSelectStation = () => {},
    onViewStationDashboard = () => {},
    onPageChange = () => {},
    onFiltersChange = () => {},
}: StationsViewProps) => {
    const [pageIndex, setPageIndex] = useState(0);
    const [filters, setFilters] = useState<StationFilters>({});
    const containerRef = useRef();
    const menuContainerRef = useRef();
    const containerSize = useResizeAware(containerRef);
    const menuSize = useResizeAware(menuContainerRef);

    const handleSelectRow = (index: number) => {
        const station = stations.at(index);
        onSelectStation(station);
    };


    const handleDashboardClick = (index: number) => {
        let station;
        if (stations.storedModels.length) {
            station = stations.at(index);
        } else {
            station = localStations.at(index);
        }
        onViewStationDashboard(station);
    };


    const handlePageChange = (index: number, size: number) => {
        setPageIndex(index);
        onPageChange(index, size);
    };

    const updateFilter = (key: string, value: any) => {
        setPageIndex(0);
        setFilters((prevValue) => {
            const newValue = { ...prevValue, [key]: value };
            return newValue;
        });
    };

    useEffect(() => {
        onFiltersChange(filters);
    }, [filters]);

    useEffect(() => {
        localStations = stations;
    }, [stations]);

    return (
        <>
            {!isUserProfile && (
                <Helmet>
                    <title>Stations | PWSWeather</title>
                </Helmet>
            )}
            <div ref={containerRef}>
                <Hide tablet desktop>
                    {includeToolbar && (
                        <Stack mb={3}>
                            <TextInput
                                size="md"
                                variant="flushed"
                                placeholder="Search..."
                                leftElement={<Icon name="search-2" color="text.base.tertiary" />}
                                onChange={(e: any) => {
                                    const value = e.target.value.trim();
                                    updateFilter('query', value);
                                }}
                            />
                            <Stack mt={2} isInline>
                                <Box
                                    width="50%"
                                    height="32px"
                                    ref={menuContainerRef}
                                >
                                    <ExpandingMenu
                                        height={32}
                                        width={menuSize.width}
                                        expandedWidth={containerSize.width}
                                        itemHeight="46px"
                                        variant="light"
                                        selectedElement={<SelectedMenuItem />}
                                        items={[
                                            {
                                                value: 'any',
                                                label: 'Any Status',
                                            },
                                            {
                                                value: 'active',
                                                label: 'Active',
                                            },
                                            {
                                                value: 'questioned',
                                                label: 'Bad Data',
                                            },
                                            {
                                                value: 'initiating',
                                                label: 'Initiating',
                                            },

                                            {
                                                value: 'failed',
                                                label: 'Failed',
                                            },
                                            {
                                                value: 'inactive',
                                                label: 'Inactive',
                                            },
                                        ]}
                                        onSelect={(item: any) => updateFilter('type', item.value)}
                                    />
                                </Box>
                                <TableFilterStack
                                    width="50%"
                                    fields={FILTER_FIELDS}
                                    isModal
                                    buttonOpts={{
                                        size: 'sm',
                                        width: '100%',
                                    }}
                                    containerOpts={{
                                        width: '300px',
                                        bg: 'bg.200',
                                    }}
                                    onFiltersChange={(value: any[]) => updateFilter('filters', value)}
                                />
                            </Stack>
                        </Stack>
                    )}
                    <Box pt={2}>
                        <StationsListView
                            stations={stations}
                            error={error}
                            pageSize={pageSize}
                            isLoading={isLoading}
                            listSize={{ width: containerSize.width || 300, height: 500 }}
                            onPageChange={handlePageChange}
                            onSelectCell={(index: number) => handleSelectRow(index)}
                        />
                    </Box>
                </Hide>
                <Hide mobile>
                    {includeToolbar && (
                        <Stack mb={3} align="baseline" isInline>
                            {/* <Select
                                size="md"
                                width="150px"
                                variant="flushed"
                                name="type"
                                defaultValue="all"
                                onChange={(e: any) => {
                                    updateFilter('type', e.target.value);
                                }}
                            >
                                <option value="all">All statuses</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </Select> */}
                            <TextInput
                                size="md"
                                variant="flushed"
                                placeholder="Search..."
                                leftElement={<Icon name="search-2" color="text.base.tertiary" />}
                                onChange={(e: any) => {
                                    updateFilter('query', e.target.value.trim());
                                }}
                            />
                            <TableFilterStack
                                fields={FILTER_FIELDS}
                                onFiltersChange={(value: any[]) => updateFilter('filters', value)}
                                containerOpts={{
                                    bg: 'bg.300',
                                }}
                            />
                        </Stack>
                    )}
                    <StationsTableView
                        stations={stations}
                        error={error}
                        onPageChange={handlePageChange}
                        onSelectRow={handleSelectRow}
                        onViewDashboard={handleDashboardClick}
                        pageIndex={pageIndex}
                        pageSize={pagination.limit}
                        pageCount={pagination.totalPages}
                        totalRows={pagination.total}
                        isLoading={isLoading}
                        fillAdjust={-98}
                        fill
                        virtualize
                        isReduced
                    />
                </Hide>
            </div>
        </>
    );
};

export default StationsView;
