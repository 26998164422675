import { isset } from '@aerisweather/javascript-sdk/dist/utils';
import { toName } from '@aerisweather/javascript-sdk/dist/utils/strings';
import { Badge, Box, BoxProps, Icon, List, Stack, Text } from 'pws-design-system/design-system';
import React from 'react';
import { AppContext } from '../../../context/auth';
import { getConfidenceStyle } from '../../../models/stations/Station';
import Stations, { Station } from '../../../models/stations/Stations';
import DataList from '../../ui/DataList';
import StatusBadge from '../../ui/StatusBadge';

const formatPlace = (location: any = {}): string => ['city', 'state', 'country']
    .reduce((result, key) => {
        let v = location[key];
        if (v) {
            if (key === 'city') {
                v = toName(v);
            } else {
                v = v.toUpperCase();
            }
        }
        result.push(v);
        return result;
    }, [])
    .filter((v) => isset(v))
    .join(', ');

const CELL_HEIGHT = 57;

const StationCell = ({
    station,
    style,
    isAdmin = false,
    onClick = () => {},
}: { station: Station, style: any; isAdmin: boolean; onClick: () => void; }) => {
    const { color: qcColor, style: qcStyle, text: qcText } = getConfidenceStyle(station.confidence);
    return (
        <div style={style}>
            <Stack p={2} justify="space-between" align="center" cursor="pointer" isInline onClick={onClick}>
                <Stack spacing={1} maxWidth="60%" flexGrow={1}>
                    <Text fontSize="md" fontWeight="bold" isTruncated>{station.displayName}</Text>
                    <Text fontSize="xs" color="text.base.tertiary" isTruncated>{formatPlace(station.location)}</Text>
                </Stack>
                <Stack align="center" isInline>
                    <Stack align="flex-end" spacing={1}>
                        <StatusBadge value={station.status} />
                        {isAdmin && (
                            <Badge variant={qcStyle} variantColor={qcColor} color={qcText}>{station.confidence}</Badge>
                        )}
                    </Stack>
                    <Box>
                        <Icon name="chevron-right" color="bg.500" size="24px" mr={-2} />
                    </Box>
                </Stack>
            </Stack>
            <Box height="1px" bg="bg.100" />
        </div>
    );
};

interface StationsListViewProps extends BoxProps {
    stations: Stations;
    error?: any;
    pageSize?: number;
    listSize?: { width: number, height: number };
    includeToolbar?: boolean;
    isLoading?: boolean;
    onPageChange?: (index: number, size: number) => void;
    onSelectCell?: (index: number) => void;
}

const StationsListView = ({
    stations,
    error,
    pageSize = 20,
    listSize = { width: 300, height: CELL_HEIGHT * 10 },
    isLoading = false,
    onPageChange = () => {},
    onSelectCell = () => {},
    ...rest
}: StationsListViewProps) => (
    <AppContext.Consumer>
        {(session) => (
            <DataList
                pageSize={pageSize}
                totalRows={stations ? stations.count : 0}
                error={error}
                isLoading={isLoading}
                onPageChange={onPageChange}
                {...rest}
            >
                <List
                    cellProvider={({ index, style }) => (
                        <StationCell
                            station={stations.at(index)}
                            onClick={() => onSelectCell(index)}
                            isAdmin={session.user.isAdmin}
                            style={style}
                        />
                    )}
                    itemCount={stations ? stations.count : 0}
                    itemSize={CELL_HEIGHT}
                    width={listSize.width}
                    height={listSize.height}
                />
            </DataList>
        )}
    </AppContext.Consumer>
);

export default StationsListView;
