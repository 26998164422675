import React from 'react';
import { BoxProps, Stack } from 'pws-design-system/design-system';
import { mediaRangeQueries } from 'pws-design-system/design-system/theme';
import StationCard from './StationCard';
import { Stations, Station } from '../../../models/stations';
import AddStationCard from './AddStationCard';
import useMediaQuery from '../../hooks/useMediaQuery';

interface StationCardListViewProps extends BoxProps {
    stations: Stations;
    onSelectStation?: (station: Station) => void;
    onViewStationDashboard?: (station: Station) => void;
    onAddStation?: () => void;
}

const StationCardListView = ({
    stations,
    onSelectStation = () => {},
    onViewStationDashboard = () => {},
    onAddStation = () => {},
    ...rest
}: StationCardListViewProps) => {
    const isInline = useMediaQuery(mediaRangeQueries['tablet-up'], true);
    return (
        <Stack spacing={3} flexWrap="wrap" isInline={isInline} {...rest}>
            {stations.models.map((station) => (
                <StationCard
                    key={`card-${station.id}`}
                    width={['100%', '300px']}
                    minHeight={['200px', '350px']}
                    station={station}
                    onSelect={() => onSelectStation(station)}
                    onViewDashboard={onViewStationDashboard}
                />
            ))}
            <AddStationCard
                width={['100%', '300px']}
                minHeight={['150px', '350px']}
                onClick={onAddStation}
            />
        </Stack>
    );
};

export default StationCardListView;
