import format from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import { Absolute, Box, BoxProps, Button, Stack, Text } from 'pws-design-system/design-system';
import React from 'react';
import { Station } from '../../../models/stations';
import Card from '../../ui/Card';
import Map from '../../ui/Map';
import PositionMarker from '../../ui/PositionMarker';
import StatusBadge from '../../ui/StatusBadge';

interface StationCardProps extends BoxProps {
    station: Station;
    onSelect?: (station: Station | any) => void;
    onViewDashboard?: (station: Station) => void;
}

const StationCard = ({
    station,
    onSelect = () => {},
    onViewDashboard = () => {},
    ...rest
}: StationCardProps) => (
    <Card
        p={[2, 3]}
        overflow="hidden"
        position="relative"
        borderColor="transparent"
        borderWidth="3px"
        borderStyle="solid"
        transition="all 0.1s ease-in-out"
        _hover={{
            borderColor: 'brand.lightBlue.base',
            transform: 'scale(1.02)',
        }}
        {...rest}
    >
        <Absolute height="150px" top="0" left="0" right="0">
            <Map
                position="absolute"
                top="0"
                left="0"
                right="0"
                height="full"
                bg="bg.500"
                aerisId="wgE96YE3scTQLKjnqiMsv"
                aerisSecret="6E9YI5H9qCO9m44qoEUccvL7UZVSrGVG3gbO7rGW"
                interactionDisabled
                config={{
                    center: {
                        lat: station.location.latitude || 47.5,
                        lon: station.location.longitude || -122.5,
                    },
                    zoom: 12,
                    map: {
                        attributionControl: false,
                        zoomControl: false,
                    },
                }}
            />
            <PositionMarker zIndex={1000} />
        </Absolute>
        <Box mt="150px" p={2}>
            <Box position="relative">
                <Absolute left="-3px" top="-42px" bg="gray.900" rounded="md" zIndex={1050}>
                    <StatusBadge value={station.status} m="4px" />
                </Absolute>
                <Text
                    mt={2}
                    mb={2}
                    variant="subheadline"
                    fontWeight="bold"
                    lineHeight="none"
                    cursor="pointer"
                    onClick={() => onSelect(station)}
                >
                    {station.displayName || station.stationId}
                </Text>
            </Box>
            <Text variant="caption1" mt={-1}>{station.location && station.location.place}</Text>
            <Box height="2px" my={[2, 3]} bg="bg.100" />
            <Stack mb="40px">
                <Text variant="caption2" color="text.base.tertiary">{station.lastUpdatedAt ? `Last updated ${formatDistance(new Date(), new Date(station.lastUpdatedAt))} ago` : 'Not sending data'}</Text>
                <Text variant="caption2" color="text.base.tertiary">{station.registeredAt && `Registered ${format(new Date(station.registeredAt), 'MMM d, yyyy')}`}</Text>
            </Stack>
            <Box position="relative">
                <Absolute bottom={0} left={0} right={0}>
                    <Stack mt={[2, 3]} justify="flex-end" isInline>
                        <Button
                            size="xs"
                            variant="outline"
                            variantColor="default"
                            onClick={(e: any) => {
                                e.stopPropagation();
                                onViewDashboard(station);
                            }}
                        >
                            Dashboard
                        </Button>
                        <Button
                            size="xs"
                            variant="solid"
                            variantColor="default"
                            onClick={() => onSelect(station)}
                        >
                            Edit
                        </Button>
                    </Stack>
                </Absolute>
            </Box>
        </Box>
    </Card>
);

export default StationCard;
